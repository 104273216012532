import React from 'react'
import Layout from "../components/layout"
import { graphql } from "gatsby"

const ArticleTemplate = ({ data }) => (
  <Layout>
    <div id="article-wrapper">
      <h2>{data.contentfulArtikel.title}</h2>
      <div dangerouslySetInnerHTML={{__html: data.contentfulArtikel.childContentfulArtikelTextTextNode.childMarkdownRemark.html}}>
      </div>
    </div>
  </Layout>
)

export default ArticleTemplate

export const query = graphql`
  query($slug: String!) {
    contentfulArtikel(slug: { eq: $slug }) {
      title
      childContentfulArtikelTextTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;